.vision_container {
    background-color: var(--third-color);
    max-width: 500px;
    width: 75%;
    margin: 0 auto;
    border-radius: 12px;
    padding: 10px 26px;
}

.vision_container p {
    text-align: center;
}

.vision_container .vision_title {
    color: var(--second-color);
    font-size: 21px;
    font-weight: 700;
    line-height: 31.5px;
}

.vision_container .vision_text {
    color: var(--second-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

@media (min-width: 900px) {
    .vision_container {
        max-width: 1300px;
        width: 85%;
    }
    .vision_title {
        font-size: 26px !important;
    }
    .vision_container .vision_text {
        font-size: 16px;
        line-height: 30px;
    }
}

@media (min-width: 1200px) {
    .vision_title {
        font-size: 32px !important;
    }
    .vision_container .vision_text {
        font-size: 18px;
        line-height: 35px;
    }
}