.characteristicsContainer {
  display: flex;
  align-items: center;
  background-color: var(--first-color);
  flex-direction: column;
  padding: 35px 0;
}

.characteristicsTitleContainer {
  width: 80%;
}

.characteristicsTitleContainer h1 {
  color: var(--fourth-color);
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  text-align: center;
}

.sliderMobile {
  width: 100%;
}

.cardDesk {
  display: none;
}

@media (min-width: 900px) {
  .characteristicsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--first-color);
    padding: 5% 2%;
    justify-content: space-around;
    background-image: url("../../../static/icons/muttu/marcaAgua.svg");
    background-repeat: no-repeat;
    background-position: left -4px bottom 55px; 
  }
  .characteristicsTitleContainer {
    width: 42%;
    margin-bottom: 250px;
  }
  .characteristicsTitleContainer h1 {
    color: var(--fourth-color);
    font-weight: 500;
    font-size: 21px;
    line-height: 48px;
  }
  .sliderMobile {
    display: none;
  }
  .cardDesk {
    display: block;
  }
}

@media (min-width: 1200px) {
  .characteristicsContainer {
    padding: 4%;
  }
  .characteristicsTitleContainer h1 {
    font-size: 32px;
  }
}
