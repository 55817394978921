.footer {
  position: static;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--first-color);
  padding: 35px 0px;
  bottom: 0px;
  width: 100%;
  height: 125px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer p {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: white;
}

.footer-text-social {
  display: flex;
  flex-direction: column;
}

.social-media {
  margin-top: 7px;
}

.social-media img {
  width: 32px;
  height: 32px;
  padding: 0px 10px;
}

.footer-legal-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px auto;
  width: 90%;
}

.footer-legal-links a {
  color: var(--fourth-color);
  font-size: 80%;
  text-align: center;
}

.footer-legal-links p {
  margin: 0 5px;
}

@media (min-width: 900px) {
  .footer-muttu-logo img {
    width: 200px;
    height: 33px;
  }
  .footer {
    height: 70px;
    padding: 10px 0px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .footer-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 24px;
  }
  .footer-text-social {
    flex-direction: row;
    align-items: center;
  }
  .footer p {
    font-size: 16px;
    line-height: 20px;
  }
  .social-media img {
    width: 45px;
    height: 45px;
  }
  .footer {
    bottom: 0px;
  }

  .footer-legal-links {
    margin: 0;
    width: 100%;
  }
  
  .footer-legal-links a {
    color: var(--fourth-color);
    text-align: start;
  }
  
  .footer-legal-links p {
    margin: 0 5px;
  }
}

@media (min-width: 1200px) {
  .footer-content {
    padding: 0 65px;
  }
  .footer-legal-links a {
    font-size: 120%;
  }
}
