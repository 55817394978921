.pricesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricesContainer p {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: var(--second-color);
  margin-top: 31px;
  margin-bottom: 48px;
}

.initialCard-prices {
  display: flex;
  align-items: center;
  width: 92%;
  height: 113px;
  box-shadow: 0px 6px 5px -1px rgba(0, 0, 0, 0.54);
  background-color: var(--third-color);
  margin: 0 auto;
  border-radius: 7px;
}

.initialCard-prices p {
  margin-top: 45px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: var(--second-color);
}

.listCard-prices {
  display: flex;
  justify-content: center;
  box-shadow: -1px 17px 28px -12px rgb(0 0 0 / 54%);
  width: 92%;
  /* margin: 7px auto; */
  margin: 30px auto 30px auto;
  padding: 15px;
  border-radius: 7px;
  /* height: 280px; */
  align-items: center;
}

.listCard-prices-items {
  width: 90%;
  padding-inline-start: 20px;
}

.listCard-prices p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

::marker {
  color: var(--third-color);
}

.lastCard-prices {
  display: flex;
  align-items: center;
  box-shadow: 0px 6px 5px -1px rgba(0, 0, 0, 0.54);
  background-color: var(--first-color);
  width: 92%;
  margin: 0 auto;
  border-radius: 7px;
  height: 113px;
}

.lastCard-prices p {
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin: 0 auto;
}

.pricesContainer button {
  width: 290px;
  height: 50px;
  margin: 50px auto 90px auto;
  padding: 1%;
  background-color: var(--third-color);
  align-self: center;
  border-radius: 25px;
  border: none;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  box-shadow: 0px 6px 5px -1px rgba(0, 0, 0, 0.54);
  color: var(--first-color);
}

.button_prices:hover {
  cursor: pointer;
  background-color: var(--first-color);
  color: var(--third-color);
}

.leftTagPrice {
  background-color: var(--third-color);
  border: 1px solid var(--second-color);
  width: fit-content;
  padding: 2px;
  border-radius: 7px;
  margin-left: 5px;
  font-size: 12px;
}

.listCard-prices-items li{
  line-height: 25px;
  margin-bottom: 10px;
}

@media (min-width: 650px) {
  .listCard-prices-items {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .listCard-prices-items li {
    margin: 0 0 0 30px;
    line-height: 38px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .lastCard-prices p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}

@media (min-width: 900px) {
  .pricesContainer .firstText {
    margin-top: 90px;
    margin-bottom: 90px;
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
  }

  .initialCard-prices p {
    font-weight: 700;
    font-size: 21px;
    line-height: 31.5px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .listCard-prices {
    height: 330px;
  }

  .listCard-prices-items li {
    font-size: 18px !important;
  }

  .lastCard-prices {
    height: 73px;
  }
  .pricesContainer button {
    margin: 45px 0 65px 0;
    width: 310px !important;
    height: 50px;
    background-color: var(--third-color);
    align-self: center;
    border-radius: 25px;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: var(--first-color);
  }
}
