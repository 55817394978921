.modal-contact-container {
    margin: 0;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: var(--third-color);
}

.modal-contact-container .modal-contact-button {
    background-color: transparent;
    border: none;
}