.ImageBannerMobile {
    width: 100%;
}

.text_banner_mobile {
    margin-top: 5px;
    padding: 0 20px 20px 20px;
    color: var(--second-color);
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    text-align: left;
}

.ImageBannerWeb {
    display: none;
}

.text_banner_web {
    display: none;
}

@media (min-width: 600px) {
    .text_banner_mobile {
        margin-top: 50px;
    }
}

@media (min-width: 900px) {
    .ImageBannerWeb {
        padding-top: 90px;
    }
    .aboutus_top_mobile {
        display: none;
    }
    .ImageBannerMobile {
        display: none;
    }
    .ImageBannerWebContainer {
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .ImageBannerWeb {
        display: block;
        width: 100%;
        max-height: 100%;
    }
    .ImageBannerWebContainer p {
        color: var(--fourth-color);
        width: 545px;
        height: 277px;
        font-size: 31px;
        font-weight: 700;
        line-height: 46.5px;
        position: absolute;
        display: block;
        top: 35%;
        left: 8%;
        text-align: left;
        text-shadow: 0 3px 3px rgb(0 0 0 / 36%);
    }
}