.downloadApp {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.downloadApp-sourse {
  width: 50%;
}

.downloadApp a {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  text-decoration: none;
  color: var(--second-color);
}

.downloadApp-sourse img {
  width: 70%;
}

.desktop-part {
  display: none;
}

#info {
  font-size: 13px;
}

@media (min-width: 750px) {
  .desktop-part {
    display: block;
    color: var(--second-color);
  }
}
