header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic {
  height: 66px;
  justify-content: center;
  background-color: var(--first-color) !important;
}

.MuiContainer-root.MuiContainer-maxWidthXl {
  max-width: 100%;
}

/* .css-hip9hq-MuiPaper-root-MuiAppBar-root {
  height: 66px;
  justify-content: center;
  background-color: var(--first-color) !important;
} */

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  padding-top: 30px !important;
  top: 66px !important;
  left: 0px !important;
  width: 100% !important;
  height: 460px !important;
  max-width: calc(100% - 0px) !important;
}

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  padding-top: 30px !important;
  top: 66px !important;
  left: 0px !important;
  width: 100% !important;
  height: 440px !important;
  max-width: calc(100% - 0px) !important;
} */

li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters {
  height: 420px !important;
  padding-top: 0px !important;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 60px !important;
  overflow: hidden !important;
}

/* .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  height: 420px !important;
  padding-top: 0px !important;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 60px !important;
  overflow: hidden !important;
} */

a:-webkit-any-link {
  text-decoration: none !important;
  color: var(--first-color);
}

#pToHeader {
  font-size: 18px;
  text-decoration: none !important;
  color: var(--first-color);
  cursor: pointer;
}

.button_container_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button_header {
  width: 142px !important;
  height: 36px !important;
  margin-top: 40px !important;
  background-color: white;
  height: 40px;
  width: 264px;
  align-self: center;
  border-radius: 25px;
  border: 1px solid var(--second-color);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.button_header:hover {
  cursor: pointer;
  background-color: var(--third-color);
}

.logoHeader:after{
  width: 0 !important;
  height: 0 !important;
}

@media (min-width: 600px) {
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    top: 67px !important;
  }
}

@media (min-width: 900px) {
  header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic {
    position: fixed !important;
    min-height: 90px;
    justify-content: center;
    z-index: 100;
  }
  .css-19wv5ok {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .button_container_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
  .button_header {
    margin-top: 0px !important;
    background-color: transparent;
    border: 1px solid var(--fourth-color);
    height: 36px;
    width: 142px;
    align-self: center;
    border-radius: 25px;
    border: 1px solid var(--fourth-color);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--fourth-color);
  }
  .button_header:hover {
    cursor: pointer;
    color: var(--second-color);
  }
  a:-webkit-any-link {
    display: inline-block;
    position: relative;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.5px;
    text-decoration: none !important;
    color: var(--fourth-color);
  }

  #pToHeader {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.5px;
    display: inline-block;
    position: relative;
    color: white;
    text-decoration: none !important;
    color: var(--fourth-color);
  }

  a:-webkit-any-link:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--third-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  #pToHeader::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--third-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  a:-webkit-any-link:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  #pToHeader:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

@media (min-width: 1200px) {
  .css-r6ewbb-MuiToolbar-root img {
    width: 198px;
    height: 35px;
  }

  a:-webkit-any-link {
    font-size: 15px;
  }

  #pToHeader {
    font-size: 15px;
  }
  header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic {
    padding-left: 77px;
    padding-right: 77px;
  }
}
