.our_enterprise_container {
  margin: 20px auto 0 auto;
  max-width: 550px;
  display: flex;
  flex-direction: column;
}

.our_enterprise_since_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.our_enterprise_title_container {
  width: 100%;
}

.our_enterprise_title {
  max-width: 550px;
  text-align: left;
  font-size: 21px;
  font-weight: 700;
  color: var(--second-color);
  padding: 20px 20px 0px 20px;
}

.our_enterprise_years {
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  border-radius: 50%;
  color: var(--second-color);
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0 0 7px;
  margin: 32px 0 0 0;
  border: 1px solid var(--first-color);
}

.our_enterprise_years:hover {
  background-color: var(--third-color);
}

.anios {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: var(--second-color);
  margin: 4px 0 16px 0;
}

.our_enterprise_description_text {
  max-width: 550px;
  margin: 0 auto 15px auto;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: var(--second-color);
}

.our_enterprise_content {
  margin-bottom: 45px;
}

.button_container_aboutus {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;
}

.button_aboutus {
  background-color: var(--third-color);
  color: var(--first-color);
  height: 40px;
  width: 264px;
  align-self: center;
  border-radius: 25px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 64px;
  box-shadow: 0px 6px 5px -1px rgba(0, 0, 0, 0.54);
}

.button_aboutus:hover {
  cursor: pointer;
  background-color: var(--first-color);
  color: var(--third-color);
}

@media (min-width: 900px) {
  .our_enterprise_container {
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .our_enterprise_content {
    display: flex;
    align-items: flex-start;
    width: 90%;
  }
  .our_enterprise_content_group {
    display: flex;
    flex-direction: column;
  }
  .our_enterprise_title {
    max-width: 1300px;
    font-size: 26px;
  }
  .our_enterprise_title_container {
    width: 90%;
    margin: 0 auto;
  }
  .our_enterprise_description_text {
    font-size: 16px;
    line-height: 30px;
  }
  .button_container_aboutus {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 73px;
  }
  .button_aboutus {
    height: 50px;
    width: 280px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 20px 0 0 0;
  }
}

@media (min-width: 1200px) {
  .our_enterprise_title {
    max-width: 1300px;
    font-size: 32px;
  }
  .our_enterprise_description_text {
    font-size: 18px;
    line-height: 35px;
  }
}
