.aboutus_description_title {
    text-align: left;
    font-size: 21px;
    font-weight: 700;
    line-height: 31.5px;
    color: var(--second-color);
    padding: 20px 20px 10px 20px;
}

.aboutus_description_text {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: var(--second-color);
    padding: 0px 20px 35px 20px;
}

.text_banner_mobile_container {
    max-width: 550px;
    margin: 0 auto;
}

.description_container_about_us {
    max-width: 550px;
    margin: 0 auto;
}

@media (min-width: 900px) {
    .description_container_about_us {
        padding: 90px 0;
        display: flex;
        max-width: 90%;
        justify-content: space-between;
        align-items: center;
    }
    .aboutus_description_title {
        padding: 0px;
        font-size: 26px;
    }
    .aboutus_description_text {
        width: 60%;
        padding: 0px;
        font-size: 16px;
        line-height: 30px;
    }
}

@media (min-width: 1200px) {
    .description_container_about_us {
        width: 1350px;
        max-width: 85%;
        padding: 90px 20px;
        justify-content: space-between;
    }
    .aboutus_description_title {
        padding: 0px;
        font-size: 32px;
    }
    .aboutus_description_text {
        width: 65%;
        padding: 0px;
        font-size: 18px;
        line-height: 35px;
    }
}