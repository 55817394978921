.container_needs {
  margin-top: 40px;
}

.title_container_needs {
  padding: 0 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title_container_needs p {
  text-align: center;
  font-size: 21px;
  font-weight: 700;
  line-height: 31.5px;
  color: var(--second-color);
}

.tf-v1-popup {
  height: 100%;
}

.container_text_needs {
  padding: 0 40px;
}

.container_text_needs p {
  text-align: center;
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--second-color);
}

.bar_complement {
  background-size: contain;
  background-repeat: no-repeat;
  width: 95%;
  max-width: 627px;
  margin: 0 auto;
  display: flex;
  height: 64px;
  transform: translate(0px, -63px);
  background: linear-gradient(
    360deg,
    #0c2022 0%,
    rgba(217, 217, 217, 0) 102.72%,
    rgba(204, 204, 204, 0) 209.8%
  );
  border-radius: 0px 0px 12px 12px;
}

.bar_complement {
  color: var(--fourth-color);
}

.imagen_cliente img {
  max-width: 85%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.bar_complement p {
  font-size: 12px;
  font-weight: 500;
  transform: translate(5px, 23px);
}

.container_logo_muttu_client img {
  margin-left: 13px;
  transform: translate(0px, 37px);
  width: 30.97px;
}

.container_point_client img {
  transform: translate(10px, 35px);
}

.client_title {
  text-align: center;
  font-size: 21px;
  font-weight: 700;
  line-height: 35px;
  color: var(--second-color);
}

.container_client_text {
  padding: 0 21px;
}

.client_text {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: var(--second-color);
}

.button_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--third-color);
}

.button_needs {
  background-color: white;
  height: 40px;
  width: 264px;
  align-self: center;
  border-radius: 25px;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 64px;
  box-shadow: 0px 5px 10px 0px rgb(49, 49, 49);
}

.button_needs:hover {
  cursor: pointer;
  background-color: var(--first-color);
  color: var(--third-color);
}

@media (max-width: 850px) {
  .container_client_2{
    background-color: var(--third-color);
    padding: 30px 0px 15px 0px;
    box-shadow: 0px 5px 10px 0px rgb(49, 49, 49);
  }
  
  .container_client_3{
    margin-top: 50px;
  }

  .container_client_4{
    background-color: var(--third-color);
    padding: 30px 0px 15px 0px;
  }

  .container_client_text {
    padding: 0 15px;
    margin-bottom: 10%;
  }
  
}

@media (min-width: 850px) {
  .container_needs {
    background-image: url("../../../static/images/home_needs/needs_green.svg");
    background-repeat: no-repeat;
    background-position: right 0px bottom -320px;
  }
  .client_title {
    text-align: start;
    font-size: 32px;
    line-height: 30px;
    margin: 15px 0px;
  }
  .client_text {
    text-align: start;
    font-size: 20px;
    line-height: 24px;
  }
  .title_container_needs p {
    font-size: 32px;
    line-height: 48px;
  }
  .container_text_needs {
    padding: 0 60px;
    max-width: 850px;
    margin: 0 auto;
  }
  .container_text_needs p {
    font-size: 21px;
    line-height: 35px;
  }
  .bar_complement p {
    font-size: 13px;
    line-height: 26px;
    transform: translate(5px, 20px);
  }

  .imagen_cliente {
    width: 50%;
  }

  .container_client_1 {
    margin-top: 60px;
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    padding: 4%;
  }
  .container_client_2 {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--third-color);
    padding: 4%;
  }
  .container_client_3 {
    margin-top: 60px;
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    padding: 4%;
  }
  .container_client_4 {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--third-color);
    padding: 4%;
  }
  .container_client_text {
    max-width: 360px;
    text-align: start;
  }
  .button_needs {
    height: 50px;
    width: 280px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
  .container_text_needs p {
    text-align: start;
  }
}

@media (min-width: 1200px) {
  .container_needs {
    background-position: right 0px bottom -220px;
  }
  .bar_complement p {
    font-size: 16px;
    transform: translate(5px, 17px);
  }
}
