.container_expectations {
    background-color: white;
    padding-top: 45px;
}

.container_card_expectations {
    display: flex;
    flex-direction: column;
}

.container_expectations .title_container_expectations {
    padding: 0 21px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container_expectations .title_container_expectations p {
    text-align: center;
    font-size: 21px;
    font-weight: 700;
    line-height: 31.5px;
    color: var(--second-color);
}

.container_expectations .card_expectations {
    width: 85%;
    padding: 10px 15px;
    margin: 15px auto;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.container_card_expectations_title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.container_card_expectations_title_beta {
    background-color: var(--third-color);
    font-weight: 400;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 3px 13px;
    border-radius: 9px;
    margin-left: 7px;
    justify-content: space-between;
}

.container_expectations .card_expectations .card_expectations_title {
    font-size: 18px;
    font-weight: 700;
    line-height: 29px;
    text-align: start;
    margin-bottom: 35px;
}

.container_expectations .card_expectations p {
    color: var(--second-color);
    text-align: start;
}

.card_expectations:hover {
    background-color: var(--third-color);
}

.card_expectations:hover .container_card_expectations_title_beta {
    background-color: var(--fourth-color);
}

@media (min-width: 900px) {
    .container_card_expectations {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .card_expectations {
        width: 385px !important;
        height: 274px;
        margin: 25px 12px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .card_expectations_title {
        text-align: center !important;
        margin-bottom: 10px !important;
        font-size: 18px !important;
    }
    .card_expectations_description {
        text-align: center !important;
        font-size: 16px !important;
    }
}