.PriceImageBannerMobile {
    width: 100%;
}

.price_text_banner_mobile {
    margin-top: 5px;
    padding: 0 20px 0 20px;
    color: var(--second-color);
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    text-align: left;
}

.PriceImageBannerWeb {
    display: none;
}

.price_text_banner_web {
    display: none;
}

.div-content-topPrices {
    display: none;
}

.container_button_prices_mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}

 .button_prices_mobile {
        margin: 20px 0;
        width: 290px !important;
        height: 50px;
        background-color: var(--third-color);
        align-self: center;
        border-radius: 25px;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: var(--first-color);
        box-shadow: 0 6px 5px -1px rgba(0, 0, 0, .54);
    }

    .button_prices_mobile:hover {
        cursor: pointer;
        background-color: var(--first-color);
        color: var(--third-color);
    }

@media (min-width: 600px) {
    .price_text_banner_mobile {
        margin-top: 30px;
        padding: 0 30px 0 30px;
    }
}

@media (min-width: 900px) {
    .container_button_prices_mobile {
        display: none;
    }
    .PriceImageBannerWeb {
        padding-top: 90px;
    }
    .price_text_banner_mobile {
        display: none;
    }
    .PriceImageBannerMobile {
        display: none;
    }
    .PriceImageBannerWebContainer {
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .PriceImageBannerWeb {
        display: block;
        width: 100%;
        max-height: 100%;
    }
    .PriceImageBannerWebContainer p {
        color: var(--fourth-color);
        width: 515px;
        height: 277px;
        font-size: 31px;
        font-weight: 700;
        line-height: 46.5px;
        display: block;
        top: 25%;
        left: 8%;
        text-align: left;
        text-shadow: 0 3px 3px rgb(0 0 0 / 36%);
    }

    .div-content-topPrices {
        display: block;
        margin-left: 3%;
        color: var(--fourth-color);
        width: 43%;
        position: absolute;
        padding: 3%;
        top: 20%;
    }

    .div-content-topPrices .text-main-card-prices {
        line-height: normal;
        width: 370px;
        height: auto;
        font-size: 23px;
    }
    .button_prices {
        margin-top: 40px;
        width: 290px !important;
        height: 50px;
        background-color: var(--third-color);
        align-self: center;
        border-radius: 25px;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: var(--first-color);
        box-shadow: 0 6px 5px -1px rgba(0, 0, 0, .54);
    }

    .button_prices:hover {
        cursor: pointer;
        background-color: var(--first-color);
        color: var(--third-color);
    }
}

@media (min-width: 1280px) {
    .div-content-topPrices .text-main-card-prices {
        width: 450px;
        font-size: 30px;
    }
}

@media (min-width: 1400px) {
    .PriceImageBannerWebContainer p {
        top: 30%;
    }
}

@media (min-width: 1600px) {
    .div-content-topPrices {
        margin-left: 3%;
        color: var(--fourth-color);
        width: 43%;
        position: absolute;
        padding: 3%;
        top: 25%;
    }
}