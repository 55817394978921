.faqContainer {
  display: flex;
  width: 95%;
  margin: 0 auto 50px;
  flex-direction: column;
}

.fq-Button {
  background-color: var(--third-color);
  color: var(--first-color);
  height: 40px;
  width: 264px;
  align-self: center;
  border-radius: 25px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 60px;
  box-shadow: 0px 6px 5px -1px rgba(0, 0, 0, 0.54);
}

.fq-Button:hover {
  cursor: pointer;
  background-color: var(--first-color);
  color: var(--third-color);
}

@media (min-width: 900px) {
  .faqContainer {
    padding-top: 10%;
    margin-bottom: 50px;
  }
  .fq-Button {
    height: 50px;
    width: 280px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
}
