.main-card-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 70px 0;
}

.div-img-topContract img {
  width: 100%;
}

.div-content-topContract {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 5% 0 5%;
}

.title-main-card {
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  text-align: left;
}

.text-main-card {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 35px;
  text-align: left;
  color: var(--second-color);
}

.button_contract {
  color: var(--first-color);
  background-color: var(--third-color);
  height: 40px;
  width: 200px;
  align-self: center;
  border-radius: 25px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 6px 5px -1px rgba(0, 0, 0, 0.54);
}

.button_contract:hover {
  cursor: pointer;
  background-color: var(--first-color);
  color: var(--third-color);
}

.div-img-topContract-web{
  display: none;
}

@media (min-width: 900px) {
  .main-card-home {
    margin: 0 auto;
    min-height: 500px;
    width: 100%;
    padding: 0;
    margin-bottom: -10px;
  }

  .div-img-topContract{
    display: none;
  }

  .div-img-topContract-web {
    display: block;
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    padding-top: 90px;
  }

  .div-img-topContract-web img{
    width: 100%;
    height: auto;
  }

  .div-content-topContract {
    color: var(--fourth-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 43%;
    position: absolute;
    right: 49%;
    padding: 3%;
    top: 2%;
    left: -5%;
  }
  .title-main-card {
    font-weight: 700;
    font-size: 23px;
    line-height: 45.5px;
  }
  .text-main-card {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 25px;
    margin-top: 5px;
    color: var(--fourth-color);
  }
  .button_contract {
    height: 30px;
    width: 210px;
    font-size: 14px;
    align-self: flex-start;
  }
  .div-content-topContract {
    padding-right: 25px;
  }
}

@media (min-width: 1200px) {

  .title-main-card{
    font-weight: 700;
    font-size: 27px;
    line-height: 40.5px;
  }

  .text-main-card{
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 35px;
    margin-top: 10px;
  }

  .button_contract{
    height: 40px;
    width: 220px;
    font-size: 16px;
    align-self: flex-start;
  }
}

@media (min-width: 1300px) {
  .div-img-topContract img {
    display: none;
  }

  .title-main-card {
    font-weight: 700;
    font-size: 31px;
    line-height: 46.5px;
  }
  .text-main-card {
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 45px;
    color: var(--fourth-color);
  }
  .button_contract {
    height: 50px;
    width: 230px;
    font-size: 18px;
    align-self: flex-start;
  }
}


