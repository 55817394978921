.characteristicCardContainer {
  display: grid;
  grid-template-columns: 300px 300px;
  grid-gap: 15px;
}

.characteristicCard {
  display: flex;
  background-color: var(--third-color);
  padding: 9%;
  border-radius: 13px;
  height: 250px;
  flex-direction: column;
  justify-content: center;
}

.characteristicCard p {
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
}

.characteristicCard span {
  background-color: white;
  width: fit-content;
  padding: 2%;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
}

span.rigthTag {
  margin-top: -2%;
  margin-left: 228px;
}

span.leftTag {
  margin-top: -8%;
  margin-left: 164px;
}

.characteristicCard.left {
  margin-top: 40px;
}

.characteristicCard.rigth {
  margin-top: -32px;
}

.cardTittle {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.characteristicCard p {
  color: var(--second-color);
}

@media (min-width: 900px) {
  .cardTittle {
    justify-content: center;
  }
  .cardTittle h2 {
    margin-left: 15px;
  }
  .characteristicCardContainer {
    display: grid;
    grid-template-columns: 300px 300px;
    grid-gap: 15px;
    margin-left: 30px;
  }

  .characteristicCard {
    display: flex;
    justify-content: center;
    height: 350px;
  }

  span.rigthTag {
    margin-top: 0px !important;
    transform: translate(-1px, -51px);
  }

  span.leftTag {
    margin-top: 0px !important;
    transform: translate(21px, -62px);
  }
}
