.container {
    background-color: var(--first-color);
    padding: 40px 0 30px 0;   
}

.container_title {
    padding: 0 21px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container hr {
    height: 0.5px;
    width: 12px;
    border-radius: 2px;
    margin-right: 20px;
    background-color: white;
    transform: translate(8px, -13px);
}

.container .container_title p {
    text-align: center;
    font-size: 21px;
    font-weight: 700;
    line-height: 27.17px;
    color: white;
}

.container_text {
    padding: 0 21px;
}

.container_text p {
    text-align: center;
    font-size : 14px;
    font-weight: 400;
    line-height: 21px;
    color: white;
}

.container_steps {
    margin: 60px auto;
    max-width: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.container_steps_items {
    display: flex;
    flex-direction: row;
}

.step_item {
    height: 150px;
    width: 95px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.step_item p {
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    color: var(--fourth-color);
}

.step_item img {
    width: 43px;
}

.number_item {
    height: 150px;
    display: flex;
    align-items: center;
}

.number_item p {
    margin-left: 5px;
    font-size: 70px;
    font-weight: 500;
    color: var(--third-color);
}

@media (min-width: 900px) {
    .container {
        padding: 50px 0 30px 0 !important;
    }
    .container_steps {
        margin: 110px 0;
        padding: 0;
    }
    .container .container_title p {
        font-size: 32px;
        line-height: 48px;
    }
    .container_text p {
        font-size : 21px;
        line-height: 31px;
    }
    .container_steps {
        max-width: none;
    }
    .step_item {
        width: 115px;
    }
    .step_item img {
        width: 70px;
    }
    .step_item p {
        font-size: 21px;
        line-height: 31px;
    }
    .number_item p {
        margin-left: 7px;
        font-size: 120px;
    }
}
        
