.carousel-wrapper {
  width: 330px;
  margin: 0 auto;
}

.carousel .slide img {
  width: 20%;
}

.characteristicCard-slider {
  display: flex;
  background-color: var(--third-color);
  padding: 10% 5% 10% 5%;
  border-radius: 13px;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  margin: 45px auto 70px auto;
  height: 350px;
  align-items: center;
}

.characteristicCard-slider_tag {
  display: flex;
  background-color: var(--third-color);
  padding: 4% 5% 23% 5%;
  border-radius: 13px;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  margin: 45px auto 70px auto;
  height: 350px;
  align-items: center;
}

.cardTittle-slider {
  width: 290px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cardTittle-slider h2 {
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
  margin-left: 15px;
}

.cardTittle-slider img {
  width: 47px;
  height: 47px;
}

.characteristicCard-slider p {
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  color: var(--second-color);
}

.characteristicCard-slider_tag p {
  margin: 0 auto;
  width: 270px;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  color: var(--second-color);
}

span.rigthTag {
  background-color: white;
  width: fit-content;
  padding: 2%;
  border-radius: 7px;
  margin-left: inherit;
  margin-top: -7%;
  margin-top: -50%;
  margin-left: 228px;
}

span.leftTag {
  background-color: white;
  width: fit-content;
  padding: 2%;
  border-radius: 7px;
  margin-left: inherit;
  margin-top: -7%;
  margin-top: -50%;
  margin-left: 145px;
}