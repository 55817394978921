.container_user_thinking {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.container_user_thinking .title_user_thinking {
    padding: 0 21px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container_user_thinking .title_user_thinking p {
    text-align: center;
    font-size: 21px;
    font-weight: 700;
    line-height: 31.5px;
    color: var(--second-color);
}

.container_user_thinking .container_card_user_thinking {
    display: flex;
}

.mySwiper {
    width: 320px;
    display: flex;
    justify-content: center;
}

.swiper-pagination-bullet:root {
    --swiper-theme-color: #3D5A80;
}

.container_user_thinking .card_user_thinking {
    padding: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container_user_thinking .card_user_thinking .card_user_thinking_name {
    text-align: center;
    font-size: 27px;
    font-weight: 700;
    line-height: 40.5px;
    color: var(--second-color);
}

.card_user_thinking_stars img {
    margin: 0 5px;
}

.card_user_thinking_text {
    margin-bottom: 35px;
    font-size: 18px;
    font-weight: 400;
    Line-height: 27px;
    color: var(--second-color);
}

.card_user_thinking_text b {
    font-weight: 700;
}

.container_card_user_thinking_web {
    display: none;
}

.button_user_thinking {
    background-color: var(--third-color);
    color: var(--first-color);
    height: 40px;
    width: 264px;
    align-self: center;
    border-radius: 25px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 30px 0 59px 0;
    box-shadow: 0px 6px 5px -1px rgba(0, 0, 0, 0.54);
}

.button_user_thinking:hover {
  cursor: pointer;
  background-color: var(--first-color);
  color: var(--third-color);
}

.swiper-pagination-bullet {
    --swiper-theme-color: #3D5A80;
}

@media (min-width:900px) {
    .container_user_thinking {
        background-image: url("../../../static/images/user_thinking/user_thinking_green.svg");
        background-repeat: no-repeat;
        background-position: left 0px bottom 110px;
    }
    .mySwiper {
        display: none;
    }
    .container_user_thinking .title_user_thinking p {
        font-size: 29px;
        margin-bottom: 40px;
    }
    .container_card_user_thinking_web {
        display: flex;
        width: 80%;
    }
    .button_user_thinking {
        height: 50px;
        width: 280px;
        font-size: 18px;
    }
}