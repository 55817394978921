#button-WhatsApp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 120px;
  right: 5px;
  background-color: #26c281;
}

button.MuiButtonBase-root.MuiFab-root.MuiFab-circular.MuiFab-sizeLarge.MuiFab-success {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 120px;
  right: 5px;
  background-color: #26c281;
}
