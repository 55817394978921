:root {
  --first-color: #0c2022;
  --second-color: #000000;
  --third-color: #d4f400;
  --fourth-color: #f6ffff;
}

body {
  position: relative;
  font-family: "Poppins", sans-serif;
  margin: 0;
  /* padding-bottom: 125px; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #edf8f8;
}

body::-webkit-scrollbar-thumb {
  background-color: #0c2022;
  border-radius: 20px;
  border: 3px solid #0c2022;
}

/* @media (min-width: 900px) {
  body {
    padding-bottom: 90px;
  }
} */
